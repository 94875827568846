import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
          <a
            href="#main-content"
            className="element-invisible element-focusable"
          >
            Informationen zum Thema Durchfall
          </a>
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/datenschutz"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
                  IMODIUM<sup>®</sup>.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-sidebar">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/teaser_03.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 15px 20px 15px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4
                        style={{
                          "font-family": "Georgia",
                          "font-style": "italic",
                          "font-weight": "normal",
                          "font-size": "20px",
                          "line-height": "26px",
                          margin: "20px 0 10px 0"
                        }}
                      >
                        Sie erreichen uns kostenlos
                        <br />
                        00800 260 260 00
                      </h4>
                      <p>
                        <span
                          style={{
                            "font-size": "13px",
                            color: "#767575"
                          }}
                        >
                          mo-fr: 09:00 - 16:00 Uhr
                        </span>
                      </p>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Datenschutzerklärung
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      <div className="field-items">
                        <div
                          className="field-item even"
                          property="content:encoded"
                        >
                          <p>ZULETZT AKTUALISIERT AM 02.06.2023</p>
                          <p>
                          Johnson &amp; Johnson GmbH engagiert sich für Ihre Privatsphäre und möchte, dass Sie mit der Art und Weise vertraut sind, wie wir Informationen erfassen, nutzen und weitergeben.  Diese Datenschutzerklärung erläutert unsere Vorgehensweisen in Bezug auf Informationen, die wir oder unsere Service-Provider mithilfe der Webseite oder Anwendung (im Folgenden als der <strong> „Service“ bezeichnet) </strong> erfassen, die von uns betrieben und kontrolliert wird und von der aus Sie auf diese Datenschutzerklärung zugreifen.  Indem Sie uns personenbezogene Daten zur Verfügung stellen oder den Service nutzen, erkennen Sie an, dass Sie diese Datenschutzerklärung gelesen und verstanden haben.
                          </p>
                          <p>
                            <strong>NUTZUNG DURCH MINDERJÄHRIGE</strong>
                          </p>
                          <p>
                          Der Service richtet sich nicht an Personen unter 14. Diese Personen dürfen den Service nur mit Zustimmung ihrer Erziehungsberechtigten nutzen. Wir erheben sonst wissentlich keine Daten von Minderjährigen, sofern nicht die Erziehungsberechtigten ihre Zustimmung erteilt haben.  Wenn ein Minderjähriger dennoch personenbezogene Daten ohne die erforderliche Zustimmung übermittelt hat und der/die Erziehungsberechtigte(n)  möchte(n), dass solche Daten entfernt werden, dann setzen Sie sich bitte mit uns in Verbindung, wie nachstehend unter „Kontakt“ erläutert.
                          </p>
                          <p>
                            <strong>
                              ERFASSUNG VON INFORMATIONEN ÜBER SIE
                            </strong>
                          </p>
                          <p>
                          Wir werden Sie gegebenenfalls auffordern, personenbezogene Daten zu übermitteln, um die beschriebenen Funktionen  (wie etwa Anmeldung für Newsletter, Tipps/Hinweise oder Bearbeitung von Bestellungen) nutzen oder um an besonderen Aktivität teilnehmen zu können (wie etwa Gewinnspiele oder andere Werbeaktionen). Sie werden darüber informiert, bei welchen Informationen es sich um Pflichtangaben handelt und welche Informationen auf freiwilliger Basis angegeben werden können.
                          </p>
                          <p>
                          Wir sind berechtigt, die von Ihnen überlassenen Informationen mit anderen von Ihnen online oder offline erfassten Informationen, wie etwa zu Ihrem bisherigen Kaufverhalten, zu kombinieren, sofern dies für die Erfüllung vertraglicher Zwecke erforderlich und/oder gesetzlich erlaubt ist. Darüber werden wir Sie entsprechend informieren. Wir können diese auch mit Informationen kombinieren, die wir über Sie von anderen Kenvue Gesellschaften 
                          </p>
                          <p>
                          Wenn Sie uns personenbezogene Daten über eine andere Person mitteilen, erklären Sie, dass Sie hierzu befugt sind und erlauben uns, diese Informationen gemäß dieser Datenschutzerklärung zu verwenden.
                          </p>
                          <p>
                            <strong>SENSIBLE INFORMATIONEN</strong>
                          </p>
                          <p>
                          Außer wenn wir Sie ausdrücklich darum ersuchen oder dazu auffordern, bitten wir Sie normalerweise nicht, uns sensible personenbezogene Daten (z. B. Sozialversicherungsnummern, Daten zu rassischer bzw. ethnischer Herkunft, zu politischen Überzeugungen, zu religiösen oder philosophische Überzeugungen, zu gesundheitlichen oder medizinischen Umständen , zum Sexualleben oder zur sexuelle Orientierung, zur kriminellen Vergangenheit oder zur Mitgliedschaft bei Gewerkschaften oder zu biometrischen bzw. genetischen Daten) zuzusenden und nicht mithilfe des Services oder auf andere Weise an uns weiterzugeben.
                          </p>
                          <p>
                            <strong>
                              PASSIVE ERFASSUNG VON INFORMATIONEN UND DEREN
                              VERWENDUNG
                            </strong>
                          </p>
                          <p>
                          Wir und unsere Service-Provider können bestimmte Informationen auf passive Weise, das heißt, ohne dass Sie sie ausdrücklich eingeben, erfassen, während Sie unseren Service  nutzen.  Bitte lesen Sie die {" "}
                            <a
                              href="/content/cookie-richtlinien/"
                              target="_blank"
                            >
                              Richtlinie zu Cookies
                            </a>
                            , um detaillierte Informationen zu Cookies und anderen Tracking-Technologien zu erhalten, die beim Besuch der Webseite oder der Anwendung aktiviert werden. Wir setzen Cookies nur, wenn das gültige Recht dies ausdrücklich erlaubt oder nachdem wir Ihr Einverständnis hierfür über das Cookie-Banner oder über die Einstellungen  erhalten haben.  Die Cookie-Richtlinie enthält Informationen darüber, wie Sie diese Technologien deaktivieren können.  
                          </p>
                          <p>
                          Wir und unsere Service-Provider können Informationen auch auf folgende Weise automatisch erheben und nutzen:
                          </p>
                          <p
                            style={{
                              "margin-left": "36.0pt"
                            }}
                          >
                            <strong>Informationen über Ihren Browser</strong>:
                            Bestimmte Informationen werden von den meisten Browsern erfasst, wie etwa Ihre MAC-Adresse (Media Access Control), Ihr Computertyp (Windows oder Mac), die Bildschirmauflösung, der Name und die Version Ihres Betriebssystems sowie Typ und Version Ihres Internetbrowsers. Wir können vergleichbare Informationen erfassen, wie zum Beispiel Ihren Gerätetyp und die entsprechende Kennnummer, wenn Sie mit einem mobilen Endgerät auf unseren Service  zugreifen. Wir nutzen diese Informationen, um sicherzustellen, dass unser Service  ordnungsgemäß funktioniert.
                          </p>
                          <p
                            style={{
                              "margin-left": "36.0pt"
                            }}
                          >
                            <strong>Informationen über Ihre IP-Adresse</strong>:
                            Ihre IP-Adresse ist eine Zahlenkombination, die Ihrem Computer automatisch durch Ihren Internetanbieter zugeteilt wird. Eine IP-Adresse wird immer dann identifiziert und automatisch in den Logdateien unseres Servers aufgezeichnet, wenn ein Nutzer unseren Service  aufruft. Die Besuchszeit und die besuchten Seiten werden ebenfalls aufgezeichnet.  Das Erfassen von IP-Adressen ist Standardpraxis im Internet und wird von vielen Online-Services automatisch durchgeführt. Wir verwenden IP-Adressen, um zum Beispiel den Umfang der Service-Nutzung zu berechnen, zur Diagnose von Server-Problemen und zur Verwaltung der Webseite oder der Anwendung. Wir können aus Ihrer IP-Adresse auch Ihren ungefähren Standort ableiten.
                          </p>
                          <p
                            style={{
                              "margin-left": "36.0pt"
                            }}
                          >
                            <strong>Informationen über Ihre Geräte</strong>: Wir können Informationen zu Ihrem Mobilgerät erfassen, wie etwa eine eindeutige Gerätekennung (IMEI), um zu verstehen, wie Sie die Webseite oder die Anwendung nutzen. Eine solche Erfassung erfolgt jedoch nur dann, wenn sie zur Bereitstellung des jeweils in Anspruch genommenen Services erforderlich ist.
                          </p>
                          <p>
                            <strong>
                              WIE WIR INFORMATIONEN VERWENDEN UND WEITERGEBEN
                            </strong>
                          </p>
                          <p>
                          Die Zwecke der Verwendung und Weitergabe der von Ihnen überlassenen Informationen erläutern wir Ihnen jeweils bei der Erhebung sowie im Folgenden:  Bitte lesen Sie auch den Abschnitt „Widerrufsrecht / Widerspruchsrecht“
 weiter unten, um zu erfahren, wie Sie bestimmten Verwendungen und Offenlegungen widersprechen können.
                          </p>
                          <p>
                          Soweit gesetzlich zulässig, werden wir Ihre Einwilligung in die Verwendung Ihrer personenbezogenen Daten zum Zeitpunkt der Datenerhebung einholen.  Wir können auch Informationen von Ihnen oder über Sie verwenden, um einen Vertrag zu erfüllen, einer gesetzlichen Verpflichtung nachzukommen (zum Beispiel aufgrund unserer Pharmakovigilanz-Verpflichtungen) oder für unsere Geschäftszwecke.  Wir können uns auch auf andere Rechtsgrundlagen stützen, insbesondere:
                          </p>
                          <ul>
                            <li>
                              <strong>
                                um die Funktionalität dieses Service zu
                                gewährleisten und Ihre Anforderungen zu
                                erfüllen.
                              </strong>
                              <ul>
                                <li>
                                um Ihnen die Funktionalität dieses Service und den zugehörigen Kundendienstleistungen zu bieten;
                                </li>
                                <li>
                                	um Ihre Anfragen zu beantworten und Ihre Wünsche zu erfüllen, wie etwa Ihnen Dokumente, die Sie anfordern, oder E-Mail-Benachrichtigungen zuzusenden;
                                </li>
                                <li>
                                um Ihnen wichtige Informationen in Bezug auf unsere Geschäftsbeziehung mit Ihnen oder auf die Webseite oder Anwendung, Änderungen unserer Bedingungen, Konditionen und Richtlinien und/oder sonstige administrative Informationen zu schicken.
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <p
                            style={{
                              "margin-left": "2.0cm"
                            }}
                          >
                            Wir werden diese Aktivitäten nutzen, um unsere
                            vertragliche Beziehung mit Ihnen zu verwalten
                            und/oder einer gesetzlichen Verpflichtung
                            nachzukommen.
                          </p>
                          <ul>
                            <li>
                              <strong>
                                Realisierung unserer Geschäftszwecke.
                              </strong>
                              <ul>
                                <li>
                                  für Datenanalysen, zum Beispiel zur
                                  Verbesserung der Effizienz des Service;
                                </li>
                                <li>
                                  für Audits – zur Überprüfung, dass unsere
                                  internen Prozesse wie beabsichtigt
                                  funktionieren und im Einklang mit
                                  gesetzlichen, regulatorischen oder
                                  vertraglichen Anforderungen stehen;
                                </li>
                                <li>
                                  zu Zwecken der Betrugs- und
                                  Sicherheitsüberwachung, zum Beispiel, um
                                  Cyberangriffe oder Versuche,
                                  Identitätsdiebstahl zu begehen, aufzudecken
                                  und zu verhindern;
                                </li>
                                <li>
                                  zur Entwicklung neuer Produkte und
                                  Dienstleistungen;
                                </li>
                                <li>
                                  zur Aufwertung, Verbesserung oder
                                  Modifizierung unserer Website oder unserer
                                  Produkte und Dienstleistungen;
                                </li>
                                <li>
                                  zur Ermittlung von Trends bei der
                                  Service-Nutzung, zum Beispiel, um zu
                                  verstehen, für welche Teile unseres Service
                                  die Nutzer sich am meisten interessieren; und
                                </li>
                                <li>
                                  zur Feststellung der Wirksamkeit unserer
                                  Werbekampagnen, damit wir unsere Kampagnen an
                                  die Bedürfnisse und Interessen unserer Nutzer
                                  anpassen können.
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <p
                            style={{
                              "margin-left": "2.0cm"
                            }}
                          >
                            Wir werden diese Aktivitäten nutzen, um unsere
                            vertragliche Beziehung mit Ihnen zu verwalten, um
                            einer gesetzlichen Verpflichtung nachzukommen
                            und/oder weil wir ein legitimes Interesse daran
                            haben.
                          </p>
                          <ul>
                            <li>
                              <strong>
                                Analyse personenbezogener Informationen, um
                                personalisierte Dienstleistungen anbieten zu
                                können.
                              </strong>
                              <ul>
                                <li>
                                  um Sie besser zu verstehen, damit wir unsere
                                  Interaktionen mit Ihnen personalisieren können
                                  und um Ihnen Informationen und/oder Angebote
                                  zukommen zu lassen, die auf Ihre Interessen
                                  abgestimmt sind;
                                </li>
                                <li>
                                  für ein besseres Verständnis Ihrer
                                  Präferenzen, um Ihnen über die Inhalte des
                                  Service zu liefern, die nach unserer
                                  Einschätzung für Sie relevant und interessant
                                  sind.
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <p
                            style={{
                              "margin-left": "2.0cm"
                            }}
                          >
                            Wir bieten personalisierte Dienstleistungen entweder
                            mit Ihrem Einverständnis oder weil wir ein legitimes
                            Interesse daran haben.
                          </p>
                          <p>
                          Wir geben außerdem mithilfe unseres Service erfasste Informationen an folgende Dritte weiter:
                          </p>
                          <ul>
                            <li>
                            An mit der Johnson & Johnson GmbH  verbundene Gesellschaften (im Folgenden „verbundene Unternehmen“ genannt) für die in dieser Datenschutzerklärung erläuterten Zwecke. Eine Liste dieser verbundenen Unternehmen finden Sie unter folgendem Link {" "}
                              <a
                                href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx"
                                target="_blank"
                              >
                                https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                              </a>
                              . Johnson &amp; Johnson GmbH bleibt jedoch für die Handhabung der gemeinsam benutzten personenbezogenen Daten die verantwortliche Stelle.
                            </li>
                            <li>
                            An unsere Geschäftspartner, mit denen wir Werbung mit gemeinsamen Marken und gemeinsamer Vermarktung anbieten
                            </li>
                            <li>
                            	An unsere Dienstleister, die Serviceleistungen wie beispielsweise Webseite-Hosting und -Moderation, Hosting mobiler Anwendungen, Datenanalyse, Zahlungsabwicklung, Auftragsabwicklung, Bereitstellung der Infrastruktur, IT-Serviceleistungen, Kundendienst, Zustelldienste für E-Mails und Direktwerbung, Auditing und andere Serviceleistungen anbieten, um diesen Service anbieten zu können, und
                            </li>
                            <li>
                            	soweit dies nach geltendem Recht zulässig ist an Dritte im Fall von Reorganisationen, Fusionen, Verkäufen, Joint-Ventures, Abtretungen, Übertragungen oder anderen Verfügungen hinsichtlich unseres ganzen Unternehmens oder unserer gesamten Vermögenswerte oder Aktien bzw. Teilen davon (beispielsweise in Zusammenhang mit Insolvenz- oder ähnlichen Verfahren).
                            </li>
                          </ul>
                          <p>
                          Darüber hinaus können Ihre Informationen von uns so verwendet und weitergegeben werden, wie wir dies für notwendig bzw. angemessen halten: (a) um den Rechtsweg oder das anwendbare Recht einzuhalten, das auch Gesetze außerhalb Ihres Wohnsitzlandes umfassen kann; (b) um auf Anfragen öffentlicher und staatlicher Behörden einzugehen, wie beispielsweise bei Nebenwirkungsmeldungen, ggf. einschließlich öffentlicher und staatlicher Behörden außerhalb Ihres Wohnsitzlandes; (c) um unsere Geschäftsbedingungen durchzusetzen; und (d) um unsere bzw. die Rechte, Privatsphäre, Sicherheit oder das Eigentum von mit uns verbundenen Unternehmen bzw. Ihnen und anderen zu schützen.  Wir können Ihre Informationen auch anderweitig verwenden und weitergeben, sofern wir Ihre Einwilligung dazu eingeholt haben.
                          </p>
                          <p>
                          Wir können Angaben, die wir automatisch erfassen, wie oben unter „Automatische Erfassung und Verwendung von Informationen“ beschrieben verwenden und weitergeben.“
                          </p>
                          <p>
                          Darüber hinaus können wir, soweit gesetzlich zulässig, Informationen, die nicht personenbezogener Natur sind, für Zwecke jeglicher Art verwenden und weiterleiten.  Wenn wir Informationen, die nicht personenbezogener Natur sind, mit identifizierbaren Informationen kombinieren (wie etwa die Verbindung Ihres Namens mit Ihrem geografischen Aufenthaltsort), behandeln wir die verbundenen Informationen als personenbezogene Daten, solange sie verbunden sind.
                          </p>
                          <p>
                            <strong>Widerrufsrecht / Widerspruchsrecht</strong>
                          </p>
                          <p>
                            <strong>
                            Widerspruch gegen die Verwendung und Weitergabe Ihrer personenbezogenen Daten durch uns
                            </strong>
                          </p>
                          <p>
                          Wir gewähren Ihnen Wahlmöglichkeiten hinsichtlich der Verwendung und der Weitergabe Ihrer personenbezogenen Daten für Marketingzwecke.  Sie können in folgenden Fällen Ihre Einwilligung für die Zukunft widerrufen:
                          </p>
                          <ul>
                            <li>
                              <strong>
                                Erhalt von Marketingmitteilungen von uns
                              </strong>
                              : Wenn Sie in Zukunft keine Marketingmitteilungen mehr von uns erhalten möchten, können Sie Ihre Zustimmung für deren Erhalt widerrufen, indem Sie uns per {" "}
                              <a
                                href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p8"
                                target="_blank"
                              >
                                Kontaktformular
                              </a>{" "}
                              kontaktieren.  Nennen Sie in Ihrer Anfrage an uns bitte Ihren Namen, kennzeichnen Sie die Art(en) von Marketingmitteilungen, die Sie nicht länger erhalten möchten, und geben Sie die Adresse(n) an, an die die Mitteilungen geschickt werden. Wenn Sie beispielsweise keine Marketing-E-Mails oder Direktwerbung mehr von uns erhalten möchten, teilen Sie uns dies mit und geben Ihren Namen und Ihre E-Mail-Adresse oder Postanschrift an. Darüber hinaus können Sie Ihre Zustimmung zum weiteren Erhalt von Marketingmitteilungen von uns im Wege der Befolgung der Abmeldungsanleitungen widerrufen, die in jeder solchen Mitteilung enthalten sind.
                            </li>
                            <li>
                              <strong>Erhalt von Erinnerungen von uns</strong>:
                              Wenn Sie in Zukunft keine medizinischen Erinnerungen mehr von uns erhalten möchten, können Sie Ihre Einwilligung für deren Erhalt widerrufen. , indem Sie uns per {" "}
                              <a
                                href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p8"
                                target="_blank"
                              >
                                Kontaktformular
                              </a>{" "}
                              kontaktieren.  Geben Sie in Ihrem Schreiben an uns bitte Ihren Namen und die E-Mail-Adresse oder Telefonnummer an, unter der Sie Erinnerungen von uns erhalten.
                            </li>
                            <li>
                              <strong>
                                Verwendung Ihrer personenbezogenen Daten durch mit uns verbundene Unternehmen und durch unsere
                                Geschäftspartner
                              </strong>
                              :  Wenn Sie sich zuvor für den Empfang von Marketingmitteilungen von unseren Partnern oder mit uns verbundenen Unternehmen von Drittanbietern entschieden haben, können Sie sich gegen eine Weitergabe Ihrer persönlichen Daten an diese Parteien für deren Direktmarketingzwecke aussprechen, indem Sie uns über {" "}
                              <a
                                href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p8"
                                target="_blank"
                              >
                                Kontaktformular
                              </a>{" "}
                              kontaktieren. Erklären Sie in Ihrer Mitteilung an uns bitte ausdrücklich, dass wir Ihre personenbezogenen Daten nicht länger an mit uns verbundene Unternehmen und/oder unsere Geschäftspartner für deren Marketingzwecke weitergeben dürfen, und geben Sie Ihren Namen und Ihre E-Mail-Adresse an.
                            </li>
                          </ul>
                          <p>
                          Wir werden uns bemühen, Ihrem Ersuchen so schnell wie möglich nachzukommen. Bitte berücksichtigen Sie, dass wir im Fall des oben beschriebenen Widerrufs Ihre personenbezogenen Daten nicht mehr aus den Datenbanken der mit uns verbundenen Unternehmen entfernen können, an die wir Ihre Daten bereits (d. h. bis zu dem Zeitpunkt, an dem wir Ihr Ersuchen um Abmeldung bearbeiten) weitergegeben haben. Wir werden jedoch angemessene Anstrengungen unternehmen, um unsere Partner über Ihre Anfrage zu informieren. Beachten Sie bitte ferner, dass wir Ihnen, auch wenn Sie Ihre Zustimmung bezüglich des weiteren Erhalts von Marketingmitteilungen widerrufen haben, wir Ihnen nach wie vor wichtige geschäftsbezogene und administrative Mitteilungen zuschicken können.
                          </p>
                          <p>
                            <strong>
                            Zugriff auf personenbezogene Daten und deren Änderung oder Löschung (Recht auf Auskunft)
                            </strong>
                          </p>
                          <p>
                          Wenn Sie Ihre personenbezogenen Daten überprüfen, korrigieren, aktualisieren, beschränken oder löschen möchten oder wenn Sie eine elektronische Kopie Ihrer personenbezogenen Daten anfordern möchten, zum Zweck der Übermittlung an ein anderes Unternehmen (soweit Ihnen diese Rechte durch geltendes Recht zur Verfügung gestellt werden), kontaktieren Sie uns bitte über {" "}
                            <a
                              href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p8"
                              target="_blank"
                            >
                              Kontaktformular
                            </a>
                            . Wir werden auf Ihre Anfrage so schnell wie möglich, spätestens jedoch einen Monat nach Erhalt reagieren. Wenn die Umstände eine Verzögerung bei unserer Antwort mit sich bringen, werden Sie umgehend benachrichtigt und erhalten ein Datum für unsere Antwort genannt.  
                          </p>
                          <p>
                            <strong>GRENZÜBERSCHREITENDE ÜBERMITTLUNG</strong>
                          </p>
                          <p>
                          Ihre personenbezogenen Daten können in jedem Land, in dem wir Niederlassungen unterhalten oder Dienstleister beschäftigen, gespeichert und verarbeitet werden, und mit der Benutzung unseres Service oder der Abgabe Ihrer Einwilligung an uns (wo gesetzlich vorgeschrieben) können Ihre Daten an Länder außerhalb Ihres Wohnsitzlandes einschließlich der Vereinigten Staaten übermittelt werden, in denen u. U. andere Datenschutzregeln als in Ihrem Land gelten. Zum Schutz personenbezogener Daten bestehen dennoch geeignete vertragliche und andere Maßnahmen, wenn sie an mit uns verbundene Unternehmen oder an Dritte in anderen Ländern weitergegeben werden.
                          </p>
                          <p>
                          Einige Länder außerhalb des Europäischen Wirtschaftsraums (EWR) werden von der Europäischen Kommission als Länder mit einem angemessenen Datenschutzniveau entsprechend den EWR-Standards anerkannt (die vollständige Liste dieser Länder finden Sie {" "}
                            <a
                              href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_de"
                              target="_blank"
                            >
                              hier
                            </a>
                            ). Für Übertragungen aus dem EWR-Raum in Länder, die von der Europäischen Kommission nicht als angemessen erachtet werden, haben wir sichergestellt, dass angemessene Maßnahmen getroffen werden, unter anderem durch die Gewährleistung, dass der Empfänger gebunden ist an <strong>EU-Standardvertragsklauseln</strong>, um Ihre personenbezogenen Daten zu schützen.  Sie können eine Kopie dieser Maßnahmen erhalten, indem Sie sich gemäß dem Abschnitt <em>„Kontakt“</em>  an unseren Datenschutzbeauftragten wenden.
                          </p>
                          <p>
                            <strong>SICHERHEIT</strong>
                          </p>
                          <p>
                          Wir treffen angemessene organisatorische, technische und administrative Vorkehrungen, um personenbezogene Daten zu schützen, die in unserem Verantwortungsbereich erhoben und verwendet werden. Leider gibt es keine Garantie für die 100-%ige Sicherheit einer Datenübertragung über das Internet oder ein Datenspeichersystem.  Wenn Sie Grund zu der Annahme haben, dass Ihre Kommunikation mit uns nicht mehr sicher ist (zum Beispiel, wenn Sie meinen, dass die Sicherheit eines Ihrer Konten bei uns gefährdet ist), teilen Sie uns dieses Problem bitte sofort mit, indem Sie uns gemäß dem nachfolgenden Abschnitt „<em>Kontakt</em>“
                            benachrichtigen.
                          </p>
                          <p>
                            <strong>AUFBEWAHRUNGSFRIST</strong>
                          </p>
                          <p>
                          Wir werden Ihre personenbezogenen Daten so lange aufbewahren, wie dies im Hinblick auf die Zwecke, zu denen sie erhoben wurden, erforderlich und zulässig ist. Die Kriterien für die Festlegung unserer Aufbewahrungsfristen umfassen: (i) die Zeitspanne, während der wir laufende Beziehung mit Ihnen unterhalten und Ihnen den Service bereitstellen; (ii) ob es eine gesetzliche Verpflichtung zur Aufbewahrung gibt, der wir unterliegen; und (iii) ob eine Aufbewahrung angesichts unserer Rechtslage erforderlich ist (beispielsweise im Hinblick auf geltende Verjährungsfristen, Rechtsstreitigkeiten oder behördliche Untersuchungen).
                          </p>
                          <p>
                          Wenn wir beispielsweise Ihre personenbezogenen Informationen für den Abschluss eines Vertrages mit Ihnen speichern, bewahren wir die Informationen noch 15 Jahre nach Auslaufen des Vertrages auf. In Fällen, bei denen Sie uns Ihr Einverständnis gegeben haben, Marketing-Informationen zu erhalten, speichern wir Ihre Daten, bis Sie Ihr Einverständnis widerrufen bzw. für 5 Jahre.
                          </p>
                          <p>
                          Beachten Sie jedoch bitte, dass dies keine umfassende Auflistung von Aufbewahrungsfristen darstellt.  Ihre personenbezogenen Daten können über einen längeren Zeitraum gespeichert werden, wobei die im ersten Absatz dieses Abschnitts genannten Kriterien, insbesondere die Punkte (ii) und (iii), gelten. 
                          </p>
                         
                          <p>
                            <strong>
                              FREMDE WEBSITES UND SERVICELEISTUNGEN
                            </strong>
                          </p>
                          <p>
                          Dieser Service kann Links zu Websites Dritter enthalten. Diese Datenschutzerklärung bezieht sich nicht auf die Regelungen zum Datenschutz, Informationen oder dergleichen von Dritten, für die wir nicht verantwortlich sind. Hierzu zählen auch solche Dritte, die eine Webseite oder einen Online-Service betreiben (einschließlich unter anderem jeder Art von Anwendungen), die über diesen Service angeboten wird oder zu der dieser Service einen Link enthält. Auch sind wir nicht für solche Regelungen von Dritten verantwortlich. Die Verfügbarkeit solcher Webseiten oder Umgebungen auf unserer Webseite oder unsere Aufnahme eines Links zum Service stellt keine entsprechende Befürwortung durch uns bzw. durch mit uns verbundene Unternehmen dar.
                          </p>
                          <p>
                            <strong>KONTAKT</strong>
                          </p>
                          <p>
                          Johnson &amp; Johnson GmbH, mit Sitz in Leopold Ungar Platz 2, 1190 Wien, Austria, ist das Unternehmen, das für die Erfassung, Verwendung und Weitergabe von personenbezogenen Daten im Rahmen dieser Datenschutzerklärung verantwortlich ist.
                          </p>
                          <p>
                            Wenn Sie Fragen zu dieser Datenschutzerklärung
                            haben, kontaktieren Sie uns bitte per{" "}
                            <a
                              href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p8"
                              target="_blank"
                            >
                              Kontaktformular
                            </a>{" "}
                            oder schreiben uns bitte an die folgende Adresse:
                          </p>
                          <p>
                            Johnson &amp; Johnson GmbH
                            <br />
                            Leopold Ungar Platz 2,
                            <br />
                            1190 Wien,
                            <br />
                            Austria
                          </p>
                          <p>
                            Sie können sich auch, falls verfügbar, über{" "}
                            <a href="mailto:emeaprivacy@kenvue.com">
                              emeaprivacy@kenvue.com
                            </a>{" "}
                            an den für Ihr Land oder Ihre Region zuständigen
                            Datenschutzbeauftragten wenden.
                          </p>
                          <p>
                            <strong>
                              EINE BESCHWERDE BEI EINER AUFSICHTSBEHÖRDE
                              EINREICHEN
                            </strong>
                          </p>
                          <p>
                            Sie können eine Beschwerde bei einer
                            Aufsichtsbehörde einreichen, die für Ihr Land oder
                            Ihre Region zuständig ist. Bitte klicken Sie{" "}
                            <a
                              href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
                              target="_blank"
                            >
                              hier
                            </a>
                            , um Kontaktinformationen zu diesen Behörden zu
                            erhalten.
                          </p>
                          <p>
                            <strong>
                              AKTUALISIERUNGEN DIESER DATENSCHUTZRICHTLINIE
                            </strong>
                          </p>
                          <p>
                          Wir können diese Datenschutzerklärung jederzeit ändern. Jegliche Änderungen dieser Datenschutzerklärung treten in Kraft, wenn sie in überarbeiteter Form online veröffentlicht werden.  Mit Ihrer Benutzung unserer Services  im Anschluss an solche Änderungen bringen Sie zum Ausdruck, dass Sie die überarbeitete Datenschutzerklärung anerkennen.  Wir empfehlen Ihnen, die Datenschutzerklärung regelmäßig zu durchzulesen, wenn Sie unsere Services nutzen.  Diese Richtlinie wurde zuletzt am 02.06.2023 aktualisiert.
                          </p>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf active-trail">
                    <a
                      href="/datenschutz/"
                      title
                      className="active-trail active"
                    >
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2023. Diese Website wird von der Johnson &amp; Johnson GmbH veröffentlicht, die allein für deren Inhalte verantwortlich ist. Sie ist für Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 10. Mai 2023, AT-IM-2300001
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbta11"
        />
      </div>
    );
  }
}

export default Page;
